import { Injectable } from '@angular/core';
import { catchError, concatMap, last, Observable, of, tap } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '@env/environment';
import { CredentialsService } from '@app/auth';
import { Credenziali } from '@app/modello/credenziali';

import { IConsensiFse, IConsenso } from '../../../modello/fse/consenso';
import { DatePipe } from '@angular/common';
import { DeleganteServiceService } from '../delegante-service.service';
import { Constants } from '@app/@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class ConsensoService {
  credentials$: Observable<Credenziali | null>;

  constructor(
    private client: HttpClient,
    private credentialService: CredentialsService,
    private datePipe: DatePipe,
    private deleganteService: DeleganteServiceService
  ) {
    this.credentials$ = this.credentialService.getCredentials();
  }

  getConsensi(): Observable<IConsensiFse> {
    const idDelega = sessionStorage.getItem(Constants.Keys.Fse.ID_DELEGA) ?? '';
    const cf = this.deleganteService.getDelegante()?.patientIdentifier
      ? this.deleganteService.getDelegante()?.patientIdentifier
      : this.credentialService.getCodiceFiscale();

    const chiaveConsensoFse = `${Constants.Keys.Fse.CONSENSI}_${cf?.toUpperCase()}`;
    const sessionStorageData = sessionStorage.getItem(chiaveConsensoFse);
    if (sessionStorageData) {
      return of(JSON.parse(sessionStorageData));
    }

    return this.client
      .get<IConsensiFse>(environment.fseServerUrl + `/assistiti/${cf}/stato-consensi`, {
        headers: idDelega !== '' ? { 'Id-Delega': idDelega } : {},
      })
      .pipe(
        last(),
        tap((consenso) => {
          sessionStorage.setItem(chiaveConsensoFse, JSON.stringify(consenso));
        })
      );
  }

  setConsenso(codiceConsenso: string, valoreConsenso: boolean): Observable<boolean> {
    const cf = this.deleganteService.getDelegante()?.patientIdentifier
      ? this.deleganteService.getDelegante()?.patientIdentifier
      : this.credentialService.getCodiceFiscale();
    const idDelega = sessionStorage.getItem(Constants.Keys.Fse.ID_DELEGA) ?? '';
    const chiaveConsensoFse = `${Constants.Keys.Fse.CONSENSI}_${cf?.toUpperCase()}`;
    const consensoModificato: IConsenso = {
      codiceConsenso,
      valoreConsenso,
      data: new Date(),
      note: '',
      descrizione: '',
    };
    const patchConsensi: IConsenso[] = [consensoModificato];
    return this.client
      .patch(environment.fseServerUrl + `/assistiti/${cf}/consensi`, patchConsensi, {
        headers: idDelega !== '' ? { 'Id-Delega': idDelega } : {},
        observe: 'response',
      })
      .pipe(
        concatMap(() => {
          const sessionStorageData = sessionStorage.getItem(chiaveConsensoFse);
          if (sessionStorageData) {
            const data: IConsensiFse = JSON.parse(sessionStorageData);
            data.listaConsensi = data.listaConsensi.map((consenso: IConsenso) => {
              if (consenso.codiceConsenso === codiceConsenso) {
                return consensoModificato;
              }
              return consenso;
            });
            sessionStorage.setItem(chiaveConsensoFse, JSON.stringify(data));
          }

          return of(true);
        }),
        catchError(() => of(false))
      );
  }

  getPdf(codiceInformativa: string): Observable<HttpResponse<Blob>> {
    return this.client.get(environment.fseServerUrl + '/informative/' + codiceInformativa, {
      observe: 'response',
      responseType: 'blob',
    });
  }
}
