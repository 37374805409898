// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.

import { env } from './.env';

export const environment = {
  production: env['PRODUCTION'] === 'true',
  fseServerUrl: '/api/fse/v1',
  prenotazioniUrl: '/api/prenotazioni/v1',
  screeningServerUrl: '/api/screening/v1',
  anagrafeServerUrl: '/api/anagrafe/v1',
  prenotazioniServerUrl: '/api/prenotazioni/v1',
  cupServerUrl: '/api/cup/v1',
  perLaMammaServerUrl: '/api/per-la-mamma/v1',
  version: env['npm_package_version'],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
