<header class="header rs-nav">
  <div class="menu-bar clearfix">
    <div class="container d-flex flex-wrap justify-content-between align-items-center">
      <div class="menu-logo col-md-auto mt-md-0 mt-2">
        <a
          *ngIf="saluteBasilicataUrl$ | async as saluteBasilicataUrl"
          class="d-flex align-items-center justify-content-center"
          [href]="saluteBasilicataUrl"
        >
          <img src="assets/logo.svg" alt="" />
          <div class="font-logo-salute">
            <h1>Salute Basilicata</h1>
            <h2>Fascicolo Sanitario Elettronico</h2>
          </div>
        </a>
      </div>
      <div *ngIf="credentials" class="user-dropdown-container align-items-center d-flex" ngbDropdown>
        <div>
          <span class="text-capitalize fst-italic fs-6 user-name d-none d-lg-block" *ngIf="accessoFacilitatoLogin">
            Accesso Facilitato
          </span>
          <span class="text-capitalize user-name d-none d-lg-block">
            {{ credentials?.given_name | lowercase }} {{ credentials?.family_name | lowercase }}
          </span>
        </div>
        <a id="user-dropdown" class="nav-link" role="button" ngbDropdownToggle>
          <img class="avatar" [src]="credentials.avatar" height="48" title="immagine profilo" alt="Immagine profilo" />
        </a>
        <div ngbDropdownMenu aria-labelledby="user-dropdown" class="dropdown-menu dropdown-menu-end">
          <div class="dropdown-header d-lg-none text-capitalize user-name">
            {{ credentials?.given_name | lowercase }} {{ credentials?.family_name | lowercase }}
          </div>
          <div class="dropdown-divider d-lg-none"></div>
          <!--<button class="dropdown-item" href="#"><i class="bi bi-gear icona-xs me-3"></i>Preferenze</button>-->
          <button class="dropdown-item" (click)="logout()">
            <i class="bi bi-box-arrow-right icona-xs me-3"></i>Esci
          </button>
        </div>
      </div>
    </div>
  </div>
</header>
