import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, concatMap, map, of, tap } from 'rxjs';

import { Constants } from '@app/@shared/constants';
import { IAgreement } from '@app/modello/screening/agreement';
import { environment } from '@env/environment';
import { PatientStatusService } from '../patient-status.service';

@Injectable({
  providedIn: 'root',
})
export class ConsensoScreeningService {
  constructor(private client: HttpClient, private patientStatusService: PatientStatusService) {}

  getConsensoPaziente(): Observable<IAgreement> {
    return this.patientStatusService.find().pipe(
      concatMap((patientStatus) => {
        return this.getAgreements(patientStatus?.patient?.id!).pipe(
          map((res: IAgreement[]) => {
            const consensoPaziente: IAgreement = res.filter((agr) => agr.idAgreement === 5047)[0]!;
            return consensoPaziente;
          })
        );
      })
    );
  }

  getConsensoMedico(): Observable<IAgreement> {
    return this.patientStatusService.find().pipe(
      concatMap((patientStatus) => {
        return this.getAgreements(patientStatus?.patient?.id!).pipe(
          map((res: IAgreement[]) => {
            const consensoPaziente: IAgreement = res.filter((agr) => agr.idAgreement === 5046)[0]!;
            return consensoPaziente;
          })
        );
      })
    );
  }

  // TODO manca implementazione
  getPdf(): Observable<HttpResponse<Blob>> {
    return this.client.get('assets/file/Informativa Privacy Screening.pdf', {
      observe: 'response',
      responseType: 'blob',
    });
  }

  getAgreements(pid: number): Observable<IAgreement[]> {
    const key = `${Constants.Keys.Screening.CONSENSI}_${pid}`;
    const consensiCached = sessionStorage.getItem(key);
    if (consensiCached) {
      return of(JSON.parse(consensiCached));
    } else {
      return this.client.get<IAgreement[]>(`${environment.screeningServerUrl}/patients/${pid}/agreements`).pipe(
        tap((agreements) => {
          sessionStorage.setItem(key, JSON.stringify(agreements));
        })
      );
    }
  }

  // TODO enum per gli id consenso
  putAgreement(pid: number, idAgreement: number, agreement: IAgreement): Observable<HttpResponse<IAgreement>> {
    const key = `${Constants.Keys.Screening.CONSENSI}_${pid}`;
    sessionStorage.removeItem(key);
    return this.client.put<IAgreement>(
      `${environment.screeningServerUrl}/patients/${pid}/agreements/${idAgreement}`,
      agreement,
      {
        observe: 'response',
      }
    );
  }
}
