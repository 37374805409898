<div class="sidebar-joke" [ngClass]="{ expandedJoke: isExpanded }"></div>
<div class="float-toogle d-md-none text-center position-fixed bg-white">
  <a role="button" (click)="handleSidebarToggle()">
    <i class="bi bi-list icona-md" title="Apri Menu"></i>
  </a>
</div>
<div class="sidebar vh-100 bg-white position-fixed overflow-auto" [ngClass]="{ expanded: isExpanded }">
  <div class="header-sidebar d-flex align-items-center">
    <a role="button" (click)="handleSidebarToggle()">
      <i *ngIf="isExpanded" class="bi bi-x-lg icona-sm" title="Chiudi Menu"></i>
      <i *ngIf="!isExpanded" class="bi bi-arrow-right icona-md" title="Apri Menu"></i>
    </a>
  </div>
  <div class="content-sidebar" [ngClass]="{ 'ps-2': isExpanded }">
    <div *ngIf="delegante$ | async" class="sidebar-menu" (click)="close()">
      <div class="sidebar-item box-torna">
        <a role="button" (click)="tornaSuFse()" class="justify-content-center">
          <i *ngIf="!isExpanded" class="bi bi-box-arrow-left icon-sm icona-tornafse" title="Torna al tuo FSE"></i>
          <span class="text-item text-end">Torna al TUO <br />Fascicolo Sanitario Elettronico</span>
        </a>
      </div>
    </div>
    <div *ngIf="isPrenotazioni && (delegante$ | async) === null">
      <h1>Prenotazioni</h1>
      <hr class="hr-2" />
      <ul class="sidebar-menu" (click)="close()">
        <li class="sidebar-item even" routerLinkActive="active" hidden="true">
          <a routerLink="/prenotazioni/prestazioni-disponibilita" role="button">
            <i
              class="bi bi-calendar2-heart icona-sm"
              [title]="isExpanded ? 'Prestazioni e Disponibilità' : ''"
              placement="end"
              container="body"
              ngbTooltip="Prestazioni e Disponibilità"
              tooltipClass="tooltip-sidebar"
              [disableTooltip]="isExpanded"
            ></i>
            <span class="text-item">Prestazioni e Disponibilità</span>
          </a>
        </li>
        <li class="sidebar-item even" routerLinkActive="active">
          <a routerLink="/prenotazioni/i-miei-appuntamenti" role="button">
            <i
              class="bi bi-calendar-week icona-sm"
              [title]="isExpanded ? 'I miei appuntamenti' : ''"
              placement="end"
              container="body"
              ngbTooltip="I miei appuntamenti"
              tooltipClass="tooltip-sidebar"
              [disableTooltip]="isExpanded"
            ></i>
            <span class="text-item">I miei appuntamenti</span>
          </a>
        </li>
        <li class="sidebar-item odd" routerLinkActive="active">
          <a routerLink="/prenotazioni/prenota-appuntamento" role="button">
            <i
              class="bi bi-calendar-plus icona-sm"
              [title]="isExpanded ? 'Prenota appuntamento' : ''"
              placement="end"
              container="body"
              ngbTooltip="Prenota appuntamento"
              tooltipClass="tooltip-sidebar"
              [disableTooltip]="isExpanded"
            ></i>
            <span class="text-item">Prenota appuntamento</span>
          </a>
        </li>
        <!-- <li class="sidebar-item even" routerLinkActive="active">
          <a
            href="https://prenota.sanita.basilicata.it/welcome?skip=true&targetState=main/booking/supplymode&targetSupplyMode=TAM"
            role="button"
          >
            <img src="../../../assets/tampone.svg" class="icona-sm img-tampone" title="Prenota tampone" />
            <span class="text-item">Prenota tampone</span>
          </a>
        </li> -->
        <li class="sidebar-item even" routerLinkActive="active">
          <a routerLink="/prenotazioni/disdici-appuntamento" role="button">
            <i
              class="bi bi-calendar-x icona-sm"
              [title]="isExpanded ? 'Disdici appuntamento' : ''"
              placement="end"
              container="body"
              ngbTooltip="Disdici appuntamento"
              tooltipClass="tooltip-sidebar"
              [disableTooltip]="isExpanded"
            ></i>
            <span class="text-item">Disdici appuntamento</span>
          </a>
        </li>
      </ul>
    </div>
    <div *ngIf="isFSE">
      <h1>Fascicolo Sanitario Elettronico</h1>
      <hr class="hr-2" />
      <ul class="sidebar-menu" (click)="close()">
        <li class="sidebar-item odd" routerLinkActive="active">
          <a routerLink="/fascicolo/documenti-e-referti" role="button">
            <i
              class="bi bi-file-earmark-text icona-sm"
              [title]="isExpanded ? 'Documenti e Referti' : ''"
              placement="end"
              container="body"
              ngbTooltip="Documenti e Referti"
              tooltipClass="tooltip-sidebar"
              [disableTooltip]="isExpanded"
            ></i>
            <span class="text-item">Documenti e referti</span>
          </a>
        </li>
        <li class="sidebar-item even" routerLinkActive="active">
          <a routerLink="/fascicolo/ricette-dematerializzate" role="button">
            <i
              class="bi bi-postcard icona-sm"
              [title]="isExpanded ? 'Ricette dematerializzate' : ''"
              placement="end"
              container="body"
              ngbTooltip="Ricette dematerializzate"
              tooltipClass="tooltip-sidebar"
              [disableTooltip]="isExpanded"
            ></i>
            <span class="text-item">Ricette dematerializzate</span>
          </a>
        </li>
        <li class="sidebar-item odd" routerLinkActive="active">
          <a routerLink="/fascicolo/gestisci-consensi" role="button">
            <i
              class="bi bi-file-earmark-lock2 icona-sm"
              [title]="isExpanded ? 'Gestisci consensi' : ''"
              placement="end"
              container="body"
              ngbTooltip="Gestisci consensi"
              tooltipClass="tooltip-sidebar"
              [disableTooltip]="isExpanded"
            ></i>
            <span class="text-item">Gestisci consensi</span>
          </a>
        </li>
        <li class="sidebar-item even" routerLinkActive="active">
          <a routerLink="/fascicolo/gestisci-deleghe" role="button">
            <i
              class="bi bi-people icona-sm"
              [title]="isExpanded ? 'Gestisci deleghe' : ''"
              placement="end"
              container="body"
              ngbTooltip="Gestisci deleghe"
              tooltipClass="tooltip-sidebar"
              [disableTooltip]="isExpanded"
            ></i>
            <span class="text-item">Gestisci deleghe</span>
          </a>
        </li>
        <li class="sidebar-item odd" routerLinkActive="active">
          <a routerLink="/fascicolo/accessi-fse" role="button">
            <i
              class="bi bi-box-arrow-in-right icona-sm"
              [title]="isExpanded ? 'Accessi al tuo FSE' : ''"
              placement="end"
              container="body"
              ngbTooltip="Accessi al tuo FSE"
              tooltipClass="tooltip-sidebar"
              [disableTooltip]="isExpanded"
            ></i>
            <span class="text-item">Accessi al tuo FSE</span>
          </a>
        </li>
        <li class="sidebar-item even" routerLinkActive="active">
          <a routerLink="/fascicolo/notifiche-fse-ini" role="button">
            <i
              class="bi bi-bell icona-sm"
              [title]="isExpanded ? 'Notifiche FSE-INI' : ''"
              placement="end"
              container="body"
              ngbTooltip="Notifiche FSE-INI"
              tooltipClass="tooltip-sidebar"
              [disableTooltip]="isExpanded"
            ></i>
            <span class="text-item">Notifiche FSE-INI</span>
          </a>
        </li>
      </ul>
    </div>
    <section *ngIf="(delegante$ | async) === null">
      <div *ngIf="isScreening">
        <h1>Screening oncologici</h1>
        <hr class="hr-2" />
        <ul class="sidebar-menu" (click)="close()">
          <li class="sidebar-item odd" routerLinkActive="active">
            <a routerLink="/screening/contatti-screening" role="button">
              <i
                class="ci icon-icona-menu-contatti-screening"
                [title]="isExpanded ? 'Contatti screening' : ''"
                placement="end"
                container="body"
                ngbTooltip="Contatti screening"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Contatti screening</span>
            </a>
          </li>
          <li class="sidebar-item even preferenze-cont" routerLinkActive="active">
            <a routerLink="/screening/preferenze-di-contatto" role="button">
              <div class="px-2">
                <img
                  class="preferenze"
                  alt="Preferenze di contatto"
                  [title]="isExpanded ? 'Preferenze di contatto' : ''"
                  placement="end"
                  container="body"
                  ngbTooltip="Preferenze di contatto"
                  tooltipClass="tooltip-sidebar"
                  [disableTooltip]="isExpanded"
                />
              </div>
              <span class="text-item">Preferenze di contatto</span>
            </a>
          </li>
          <li class="sidebar-item odd" routerLinkActive="active">
            <a routerLink="/screening/appuntamenti-esiti" role="button">
              <i
                class="ci icon-icona-menu-appuntamenti-screening"
                [title]="isExpanded ? 'Appuntamenti ed esiti' : ''"
                placement="end"
                container="body"
                ngbTooltip="Appuntamenti ed esiti"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Appuntamenti ed esiti</span>
            </a>
          </li>
          <li class="sidebar-item even" routerLinkActive="active">
            <a routerLink="/screening/aderisci-campagna" role="button">
              <i
                class="ci icon-icona-menu-campagna-screening"
                [title]="isExpanded ? 'Aderisci alla campagna' : ''"
                placement="end"
                container="body"
                ngbTooltip="Aderisci alla campagna"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Aderisci alla Campagna</span>
            </a>
          </li>
          <li class="sidebar-item odd" routerLinkActive="active">
            <a routerLink="/screening/consenso-screening" role="button">
              <i
                class="bi bi-file-earmark-lock2 icona-sm"
                [title]="isExpanded ? 'Gestisci consenso' : ''"
                placement="end"
                container="body"
                ngbTooltip="Gestisci consenso"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Gestisci consenso</span>
            </a>
          </li>
        </ul>
      </div>
      <div>
        <h1>Anagrafe</h1>
        <hr class="hr-2" />
        <ul class="sidebar-menu">
          <li class="sidebar-item odd" routerLinkActive="active">
            <a role="button" (click)="accediASceltaMedico()">
              <i
                class="bi bi-person-plus icona-sm"
                [title]="isExpanded ? 'Accedi a scelta medico' : ''"
                placement="end"
                container="body"
                ngbTooltip="Accedi a scelta medico"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Accedi a scelta medico</span>
            </a>
          </li>
        </ul>
      </div>
      <div *ngIf="isPerLaMamma">
        <h1>Per la mamma</h1>
        <hr class="hr-2" />
        <ul class="sidebar-menu" (click)="close()">
          <li class="sidebar-item even le-mie-gravidanze" routerLinkActive="active">
            <a routerLink="/per-la-mamma/le-mie-gravidanze" role="button">
              <div class="px-2">
                <img
                  class="img-baby"
                  [title]="isExpanded ? 'Le mie gravidanze' : ''"
                  placement="end"
                  container="body"
                  ngbTooltip="Le mie gravidanze"
                  tooltipClass="tooltip-sidebar"
                  [disableTooltip]="isExpanded"
                />
              </div>
              <span class="text-item">Le mie gravidanze</span>
            </a>
          </li>
          <li class="sidebar-item odd le-fasi-della-gravidanza" routerLinkActive="active">
            <a routerLink="/per-la-mamma/le-fasi-della-gravidanza" role="button">
              <div class="px-2">
                <img
                  class="img-mother"
                  [title]="isExpanded ? 'Le fasi della gravidanza' : ''"
                  placement="end"
                  container="body"
                  ngbTooltip="Le fasi della gravidanza"
                  tooltipClass="tooltip-sidebar"
                  [disableTooltip]="isExpanded"
                />
              </div>
              <span class="text-item">Le fasi della gravidanza</span>
            </a>
          </li>
          <li class="sidebar-item even" routerLinkActive="active">
            <a routerLink="/per-la-mamma/diario-della-gravidanza" role="button">
              <i
                class="bi bi-journal-bookmark icona-sm"
                [title]="isExpanded ? 'Diario della gravidanza' : ''"
                placement="end"
                container="body"
                ngbTooltip="Diario della gravidanza"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Diario della gravidanza</span>
            </a>
          </li>
          <li class="sidebar-item odd">
            <!-- <a href="https://prenota.sanita.basilicata.it/welcome?skip=true" role="button"> -->
            <a routerLink="/prenotazioni/i-miei-appuntamenti" role="button">
              <i
                class="bi bi-calendar-week icona-sm"
                [title]="isExpanded ? 'Appuntamenti prenotati' : ''"
                placement="end"
                container="body"
                ngbTooltip="Appuntamenti prenotati"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Appuntamenti prenotati</span>
            </a>
          </li>
          <li class="sidebar-item even" routerLinkActive="active">
            <a routerLink="/per-la-mamma/enti-rosa" role="button">
              <i
                class="bi bi-hospital icona-sm"
                [title]="isExpanded ? 'Enti rosa' : ''"
                placement="end"
                container="body"
                ngbTooltip="Enti rosa"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Enti rosa</span>
            </a>
          </li>
          <li class="sidebar-item odd" routerLinkActive="active">
            <a routerLink="/per-la-mamma/prestazioni-agevolazioni" role="button">
              <i
                class="bi bi-cash-stack icona-sm"
                [title]="isExpanded ? 'Prestazioni ed agevolazioni' : ''"
                placement="end"
                container="body"
                ngbTooltip="Prestazioni ed agevolazioni"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Prestazioni ed agevolazioni</span>
            </a>
          </li>
        </ul>
      </div>
      <div *ngIf="isAnagrafe">
        <h1>Portale Anagrafe</h1>
        <hr class="hr-2" />
        <ul class="sidebar-menu">
          <li class="sidebar-item odd" routerLinkActive="active">
            <a href="https://sceltamedico.aspbasilicata.it:8443/sceltamedico/" role="button">
              <i
                class="bi bi-person-plus icona-sm"
                [title]="isExpanded ? 'Scelta medico' : ''"
                placement="end"
                container="body"
                ngbTooltip="Scelta medico"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Scelta medico</span>
            </a>
          </li>
          <li class="sidebar-item even" routerLinkActive="active">
            <a href="https://sceltamedico.aspbasilicata.it:8443/sceltamedico/" role="button">
              <i
                class="bi bi-person-x icona-sm"
                [title]="isExpanded ? 'Revoca medico' : ''"
                placement="end"
                container="body"
                ngbTooltip="Revoca medico"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Revoca medico</span>
            </a>
          </li>
          <li class="sidebar-item odd" routerLinkActive="active">
            <a href="https://sceltamedico.aspbasilicata.it:8443/sceltamedico/" role="button">
              <i
                class="bi bi-journal-text icona-sm"
                [title]="isExpanded ? 'Riepilogo eventi' : ''"
                placement="end"
                container="body"
                ngbTooltip="Riepilogo eventi"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Riepilogo eventi</span>
            </a>
          </li>
          <li class="sidebar-item even" routerLinkActive="active">
            <a href="https://sceltamedico.aspbasilicata.it:8443/sceltamedico/" role="button">
              <i
                class="bi bi-book icona-sm"
                [title]="isExpanded ? 'Stampa libretto' : ''"
                placement="end"
                container="body"
                ngbTooltip="Stampa libretto"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Stampa libretto</span>
            </a>
          </li>
          <li class="sidebar-item odd" routerLinkActive="active">
            <a href="https://sceltamedico.aspbasilicata.it:8443/sceltamedico/" role="button">
              <i
                class="fas fa-stethoscope icona-sm"
                [title]="isExpanded ? 'Richiedi esenzione Patologia' : ''"
                placement="end"
                container="body"
                ngbTooltip="Richiedi esenzione Patologia"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Richiedi esenzione Patologia</span>
            </a>
          </li>
          <li class="sidebar-item even" routerLinkActive="active">
            <a href="https://sceltamedico.aspbasilicata.it:8443/sceltamedico/" role="button">
              <i
                class="bi bi-cash-stack icona-sm"
                [title]="isExpanded ? 'Richiedi esenzione Reddito' : ''"
                placement="end"
                container="body"
                ngbTooltip="Richiedi esenzione Reddito"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Richiedi esenzione Reddito</span>
            </a>
          </li>
        </ul>
      </div>
      <div *ngIf="isMedicinaBase">
        <h1>Medicina di base</h1>
        <hr class="hr-2" />
        <ul class="sidebar-menu">
          <li class="sidebar-item odd" routerLinkActive="active">
            <a href="https://medicidibase.sanita.basilicata.it:8448/medicinadibase/desktop.zul" role="button">
              <i
                class="bi bi-person-lines-fill icona-sm"
                [title]="isExpanded ? 'Medici non massimalisti' : ''"
                placement="end"
                container="body"
                ngbTooltip="Medici non massimalisti"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Medici non massimalisti</span>
            </a>
          </li>
          <li class="sidebar-item even" routerLinkActive="active">
            <a href="https://medicidibase.sanita.basilicata.it:8448/medicinadibase/desktop.zul" role="button">
              <i
                class="bi bi-clock icona-sm"
                [title]="isExpanded ? 'Orari di visita MMG' : ''"
                placement="end"
                container="body"
                ngbTooltip="Orari di visita MMG"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Orari di visita MMG</span>
            </a>
          </li>
          <li class="sidebar-item odd" routerLinkActive="active">
            <a href="https://medicidibase.sanita.basilicata.it:8448/medicinadibase/desktop.zul" role="button">
              <i
                class="bi bi-postcard-heart icona-sm"
                [title]="isExpanded ? 'Esenzioni' : ''"
                placement="end"
                container="body"
                ngbTooltip="Esenzioni"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Esenzioni</span>
            </a>
          </li>
        </ul>
      </div>
      <div *ngIf="isUtilita">
        <h1>Utilità</h1>
        <hr class="hr-3" />
        <ul class="sidebar-menu">
          <li class="sidebar-item odd" routerLinkActive="active">
            <a routerLink="#" role="button">
              <i
                class="bi bi-virus icona-sm"
                [title]="isExpanded ? 'Notizie Coronavirus' : ''"
                placement="end"
                container="body"
                ngbTooltip="Notizie Coronavirus"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Notizie Coronavirus</span>
            </a>
          </li>
          <li class="sidebar-item even" routerLinkActive="active">
            <a routerLink="#" role="button">
              <i
                class="bi bi-exclamation-triangle icona-sm"
                [title]="isExpanded ? 'Segnala un problema' : ''"
                placement="end"
                container="body"
                ngbTooltip="Segnala un problema"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Segnala un problema</span>
            </a>
          </li>
          <li class="sidebar-item odd" routerLinkActive="active">
            <a routerLink="#" role="button">
              <i
                class="bi bi-headset icona-sm"
                [title]="isExpanded ? 'Portale assistenza' : ''"
                placement="end"
                container="body"
                ngbTooltip="Portale assistenza"
                tooltipClass="tooltip-sidebar"
                [disableTooltip]="isExpanded"
              ></i>
              <span class="text-item">Portale assistenza</span>
            </a>
          </li>
        </ul>
      </div>
    </section>
  </div>
</div>
