import { Component, OnInit } from '@angular/core';
import { ProfileService } from '@app/@shared/profiles/profile.service';
import { NgbCalendar, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '@app/@shared/constants';
import { ProfileInfo } from '@app/@shared/profiles/profile-info.model';
import { IPersona } from '@app/modello/persona';
import { Observable, combineLatest } from 'rxjs';
import { DeleganteServiceService } from '../fse/delegante-service.service';
import { HomeService } from './home.service';
import { HttpResponse } from '@angular/common/http';
import { IAssistito } from '@app/modello/assistito';
import { ModalSceltaAslComponent } from '../anagrafe/modal-scelta-asl.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public isFseCollapsed = false;
  public isScreeningCollapsed = false;
  public isPrenotazioniCollapsed = false;
  public isAnagrafeCollapsed = false;
  public isPerLaMammaCollapsed = false;
  public delegante$: Observable<IPersona | null>;
  public delegante: IPersona | null = null;
  ricerca?: string;
  categoria?: string | '' = '';
  fse: boolean = false;
  screening: boolean = false;
  prenotazioni: boolean = false;
  anagrafe: boolean = false;
  medicinaBase: boolean = false;
  utilita: boolean = false;
  perLaMamma: boolean = false;
  inf?: ProfileInfo;
  filtri: boolean = true;
  error: boolean = false;
  public modalRef: NgbModalRef | null = null;

  constructor(
    private ngbCalendar: NgbCalendar,
    private servizioInfo: ProfileService,
    private deleganteService: DeleganteServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private service: HomeService
  ) {
    this.delegante$ = deleganteService.delegante$;
    this.delegante = JSON.parse(sessionStorage.getItem(Constants.Keys.Fse.DELEGANTE)!);
    this.servizioInfo.getProfileInfo().subscribe((info) => {
      this.inf = info;
    });
  }

  ngOnInit(): void {
    this.error = false;
    this.handleNavigation();
    if (this.router.url === '/fascicolo') {
      this.fse = true;
      this.screening = false;
      this.prenotazioni = false;
      this.anagrafe = false;
      this.medicinaBase = false;
      this.utilita = false;
      this.perLaMamma = false;
      this.filtri = false;
    } else if (this.router.url === '/screening') {
      if (this.delegante !== null) {
        this.error = true;
        this.filtri = false;
      } else {
        this.fse = false;
        this.prenotazioni = false;
        this.screening = true;
        this.anagrafe = false;
        this.medicinaBase = false;
        this.utilita = false;
        this.perLaMamma = false;
        this.filtri = false;
      }
    } else if (this.router.url === '/prenotazioni') {
      this.fse = false;
      this.screening = false;
      this.prenotazioni = true;
      this.anagrafe = false;
      this.medicinaBase = false;
      this.utilita = false;
      this.perLaMamma = false;
      this.filtri = false;
    } else if (this.router.url === '/per-la-mamma') {
      if (this.delegante !== null) {
        this.error = true;
        this.filtri = false;
      } else {
        this.fse = false;
        this.prenotazioni = false;
        this.screening = false;
        this.anagrafe = false;
        this.medicinaBase = false;
        this.utilita = false;
        this.perLaMamma = true;
        this.filtri = false;
      }
    } else {
      if (this.ricerca !== '') {
        this.ricercaTestuale();
      } else if (this.categoria !== '') {
        this.selezioneCategoria();
      } else {
        this.load();
      }
    }
  }

  transitionFiltri(): void {
    this.router.navigate(['./'], {
      relativeTo: this.route.parent,
      queryParams: {
        ricerca: this.ricerca,
        categoria: this.categoria,
      },
    });
  }

  load(): void {
    this.fse = false;
    this.screening = false;
    this.prenotazioni = false;
    this.anagrafe = false;
    this.medicinaBase = false;
    this.utilita = false;
    this.perLaMamma = false;
    this.servizioInfo.getProfileInfo().subscribe((info) => {
      this.inf = info;
      if (info.microservices != null && info.microservices.length > 0) {
        if (info.microservices.includes(Constants.FSE)) {
          this.fse = true;
          this.anagrafe = true;
        }
        if (info.microservices.includes(Constants.SCREENING)) {
          this.screening = true;
        }
        if (info.microservices.includes(Constants.PRENOTAZIONI)) {
          this.prenotazioni = true;
        }
        if (info.microservices.includes(Constants.PER_LA_MAMMA)) {
          this.perLaMamma = true;
        }
        // if (info.microservices.includes(Constants.PORTALE_ANAGRAFE)) {
        //   this.anagrafe = true;
        // }
        // if (info.microservices.includes(Constants.MEDICINA_DI_BASE)) {
        //   this.medicinaBase = true;
        // }
        // if (info.microservices.includes(Constants.UTILITA)) {
        //   this.utilita = true;
        // }
      } else {
        this.fse = true;
        this.screening = true;
        this.prenotazioni = true;
        this.anagrafe = true;
        this.medicinaBase = true;
        this.utilita = true;
        this.perLaMamma = true;
      }
    });
  }

  selezioneCategoria(): void {
    this.ricerca = '';
    if (this.categoria === '') {
      this.load();
    } else if (this.categoria === 'fse') {
      this.fse = true;
      this.screening = false;
      this.prenotazioni = false;
      this.anagrafe = false;
      this.medicinaBase = false;
      this.utilita = false;
      this.perLaMamma = false;
    } else if (this.categoria === 'screening') {
      this.fse = false;
      this.screening = true;
      this.prenotazioni = false;
      this.anagrafe = false;
      this.medicinaBase = false;
      this.utilita = false;
      this.perLaMamma = false;
    } else if (this.categoria === 'prenotazioni') {
      this.fse = false;
      this.screening = false;
      this.prenotazioni = true;
      this.anagrafe = false;
      this.medicinaBase = false;
      this.utilita = false;
      this.perLaMamma = false;
    } else if (this.categoria === 'anagrafe') {
      this.fse = false;
      this.screening = false;
      this.prenotazioni = false;
      this.anagrafe = true;
      this.medicinaBase = false;
      this.utilita = false;
      this.perLaMamma = false;
    } else if (this.categoria === 'perLaMamma') {
      this.fse = false;
      this.screening = false;
      this.prenotazioni = false;
      this.anagrafe = false;
      this.medicinaBase = false;
      this.utilita = false;
      this.perLaMamma = true;
    } else {
      this.fse = false;
      this.screening = false;
      this.prenotazioni = false;
      this.anagrafe = false;
      this.medicinaBase = false;
      this.utilita = false;
      this.perLaMamma = false;
    }
    this.transitionFiltri();
  }

  ricercaTestuale(): void {
    this.categoria = '';
    this.fse = false;
    this.screening = false;
    this.prenotazioni = false;
    this.anagrafe = false;
    this.medicinaBase = false;
    this.utilita = false;
    this.perLaMamma = false;
    this.error = false;
    if (!this.ricerca || this.ricerca === '') {
      this.load();
    } else if (
      (this.ricerca?.toLowerCase().includes('consensi') || this.ricerca?.toLowerCase().includes('gestisci')) &&
      this.delegante === null
    ) {
      this.fse = true;
      this.screening = true;
    } else if (
      this.ricerca?.toLowerCase().includes('documenti') ||
      this.ricerca?.toLowerCase().includes('referti') ||
      this.ricerca?.toLowerCase().includes('ricette') ||
      this.ricerca?.toLowerCase().includes('accessi') ||
      this.ricerca?.toLowerCase().includes('notifiche') ||
      this.ricerca?.toLowerCase().includes('deleghe') ||
      this.ricerca?.toLowerCase().includes('consensi') ||
      this.ricerca?.toLowerCase().includes('gestisci') ||
      this.ricerca?.toLowerCase().includes('online')
    ) {
      if (
        (this.inf?.microservices != null &&
          this.inf?.microservices.length > 0 &&
          this.inf?.microservices?.includes(Constants.FSE)) ||
        this.inf?.microservices == null ||
        this.inf?.microservices.length <= 0
      ) {
        this.fse = true;
        this.screening = false;
        this.prenotazioni = false;
        this.anagrafe = false;
        this.medicinaBase = false;
        this.utilita = false;
        this.perLaMamma = false;
      }
    } else if (
      (this.ricerca?.toLowerCase().includes('contatti') ||
        this.ricerca?.toLowerCase().includes('preferenze') ||
        this.ricerca?.toLowerCase().includes('contatto') ||
        this.ricerca?.toLowerCase().includes('appuntamenti') ||
        this.ricerca?.toLowerCase().includes('esiti') ||
        this.ricerca?.toLowerCase().includes('aderisci') ||
        this.ricerca?.toLowerCase().includes('campagna') ||
        this.ricerca?.toLowerCase().includes('gestisci') ||
        this.ricerca?.toLowerCase().includes('consensi')) &&
      this.delegante === null
    ) {
      if (
        (this.inf?.microservices != null &&
          this.inf?.microservices.length > 0 &&
          this.inf?.microservices?.includes(Constants.SCREENING)) ||
        this.inf?.microservices == null ||
        this.inf?.microservices.length <= 0
      ) {
        this.fse = false;
        this.screening = true;
        this.prenotazioni = false;
        this.anagrafe = false;
        this.medicinaBase = false;
        this.utilita = false;
        this.perLaMamma = false;
      }
    } else if (
      (this.ricerca?.toLowerCase().includes('disdici') ||
        this.ricerca?.toLowerCase().includes('prenotazione') ||
        this.ricerca?.toLowerCase().includes('prenota') ||
        this.ricerca?.toLowerCase().includes('visita') ||
        this.ricerca?.toLowerCase().includes('prenotazioni') ||
        this.ricerca?.toLowerCase().includes('appuntamento') ||
        this.ricerca?.toLowerCase().includes('appuntamenti') ||
        this.ricerca?.toLowerCase().includes('tampone')) &&
      this.delegante === null
    ) {
      if (
        (this.inf?.microservices != null &&
          this.inf?.microservices.length > 0 &&
          this.inf?.microservices?.includes(Constants.PRENOTAZIONI)) ||
        this.inf?.microservices == null ||
        this.inf?.microservices.length <= 0
      ) {
        this.fse = false;
        this.screening = false;
        this.prenotazioni = true;
        this.anagrafe = false;
        this.medicinaBase = false;
        this.utilita = false;
        this.perLaMamma = false;
      }
    } else if (
      (this.ricerca?.toLowerCase().includes('gravidanze') ||
        this.ricerca?.toLowerCase().includes('gravidanza') ||
        this.ricerca?.toLowerCase().includes('fasi') ||
        this.ricerca?.toLowerCase().includes('diario') ||
        this.ricerca?.toLowerCase().includes('appuntamenti') ||
        this.ricerca?.toLowerCase().includes('prenotati') ||
        this.ricerca?.toLowerCase().includes('enti') ||
        this.ricerca?.toLowerCase().includes('rosa') ||
        this.ricerca?.toLowerCase().includes('agevolazioni') ||
        this.ricerca?.toLowerCase().includes('prestazioni')) &&
      this.delegante === null
    ) {
      if (
        (this.inf?.microservices != null &&
          this.inf?.microservices.length > 0 &&
          this.inf?.microservices?.includes(Constants.PER_LA_MAMMA)) ||
        this.inf?.microservices == null ||
        this.inf?.microservices.length <= 0
      ) {
        this.fse = false;
        this.screening = false;
        this.prenotazioni = false;
        this.anagrafe = false;
        this.medicinaBase = false;
        this.utilita = false;
        this.perLaMamma = true;
      }
    } else if (
      (this.ricerca?.toLowerCase()?.includes('scelta') || this.ricerca?.toLowerCase()?.includes('medico')) &&
      this.delegante === null
    ) {
      this.fse = false;
      this.screening = false;
      this.prenotazioni = false;
      this.anagrafe = true;
      this.medicinaBase = false;
      this.utilita = false;
      this.perLaMamma = false;
    } else {
      this.fse = false;
      this.screening = false;
      this.prenotazioni = false;
      this.anagrafe = false;
      this.medicinaBase = false;
      this.utilita = false;
      this.perLaMamma = false;
      this.error = true;
    }
    this.transitionFiltri();
  }

  accediASceltaMedico(): void {
    this.service.getAnagrafeAssistito().subscribe({
      next: (res: HttpResponse<IAssistito>) => {
        if (res.body?.aslDomicilio !== null) {
          if (res.body?.aslDomicilio.codiceAzienda === '201') {
            window.location.href = 'https://sceltamedico.aspbasilicata.it:8443/sceltamedico/';
          } else {
            window.location.href = 'https://sceltamedico.asmbasilicata.it:8443/sceltamedico';
          }
        } else {
          this.modalRef = this.modalService.open(ModalSceltaAslComponent, { centered: true });
        }
      },
      error: () => {
        this.modalRef = this.modalService.open(ModalSceltaAslComponent, { centered: true });
      },
    });
  }

  private handleNavigation(): void {
    combineLatest([this.route.data, this.route.queryParamMap]).subscribe(([_data, params]) => {
      const ricerca = params.get('ricerca') ?? '';
      this.ricerca = ricerca ?? '';

      const categoria = params.get('categoria') ?? '';
      this.categoria = categoria ?? '';
    });
  }
}
