import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationGuard, AuthenticationService } from '@app/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SceltaTipologiaLoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private authGuard: AuthenticationGuard,
    private authService: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.isAutenticatoConSisir() && !this.authService.isAccessoFacilitato()) {
      this.router.navigate(['/scelta-autenticazione'], {
        state: {
          redirectTo: state.url,
        },
      });
      return false;
    } else {
      // this.authGuard.canActivate(route, state);
      return true;
    }
  }
}
