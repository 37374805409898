<footer class="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row d-flex justify-content-between">
        <div class="col-lg-3 col-md-5 col-12 footer-menu my-3 my-lg-none order-0 ms-3 salute-bas">
          <div class="menu-logo d-flex flex-column justify-content-start">
            <a class="d-flex align-items-center justify-content-start">
              <img src="assets/logo.svg" alt="" />
              <div class="font-logo-salute">
                <h2>Salute Basilicata</h2>
                <h3>Fascicolo Sanitario Elettronico</h3>
              </div>
            </a>

            <div class="copyright mt-2">
              <span>
                © Regione Basilicata {{ today | date : 'YYYY' }} <br />
                Tutti i diritti sono riservati
              </span>
            </div>
            <div class="version fst-italic mt-2">
              <span>v{{ version }}</span>
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-12 footer-menu my-3 my-lg-none order-2 order-lg-1 d-flex flex-column justify-content-start align-items-center"
        >
          <div>
            <label class="title-link text-primary">Link utili</label>
            <ul class="footer-items">
              <li>
                <a href="{{ indirizzoPortale }}/informativa-privacy/">Informativa privacy</a>
              </li>
              <li>
                <a href="{{ indirizzoPortale }}/cookie-policy/">Informativa cookie</a>
              </li>
              <li>
                <a href="{{ indirizzoPortale }}/termini-di-utilizzo/">Termini di utilizzo</a>
              </li>
              <!-- <li>
                <a href="#">Dichiarazione di accessibilità</a>
              </li> -->
            </ul>
          </div>
        </div>
        <div
          class="col-lg-3 col-md-6 col-12 footer-menu my-3 my-lg-none order-3 order-lg-2 d-flex flex-column justify-content-start"
        >
          <div>
            <div class="footer-title mb-2" *ngIf="!isMobile()">
              <label class="title-link-app text-primary">Scarica l'app!</label>
            </div>
            <div class="footer-title mb-2 d-flex justify-content-center align-items-center" *ngIf="isMobile()">
              <label class="title-link-app text-primary">Scarica l'app!</label>
            </div>
            <div class="d-flex">
              <div class="col-6 d-flex justify-content-center pe-2 py-2">
                <a
                  href="https://play.google.com/store/apps/details?id=it.apperosrl.salutebasilicata"
                  target="_blank"
                  title="App android"
                >
                  <img src="assets/badge-google-play.png" alt="App android" />
                </a>
              </div>
              <div class="col-6 d-flex justify-content-center ps-2 py-2">
                <a href="https://apps.apple.com/it/app/salute-basilicata/id1616633761" target="_blank" title="App Ios">
                  <img src="assets/badge-app-store-bianco.png" alt="App IOS" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-12 footer-menu my-3 my-lg-none order-1 order-lg-3 partner ms-3">
          <div class="d-flex flex-column">
            <label class="title-link-app text-primary mb-3">Partner tecnologico</label>
            <a href="https://www.gpi.it/" target="_blank" title="GPI" rel="noopener">
              <img class="logo-gpi" src="assets/logo-gpi.svg" alt="GPI" />
            </a>
          </div>
        </div>
      </div>
      <div class="border-top my-2"></div>
      <div class="d-flex justify-content-between align-items-center flex-wrap">
        <img src="assets/LogotipoFESR1420_Positivo.svg" alt="FESR BasilicataEuropa" />
        <img src="assets/LogotipoFESR1420_SoloLoghiIstituzionali_Positivo.svg" alt="Loghi Istituzionali" />
      </div>
    </div>
  </div>
</footer>
